import axios from 'axios'

export const getOrderFromKlarnaId = async ({ klarnaId }) => {
  try {
    const url =
      process.env.REACT_APP_API_URL + '/getOrderFromKlarnaId/?klarnaId=' + klarnaId
    const response = await axios({
      method: 'get',
      url: url,
      headers: {
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.log('erororo', e.response.data)
  }
}
