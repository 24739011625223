export const se = {
  "start_title": "Välkommen!",
  "start_text": "Du kommer nu gå igenom tre korta steg för att beskriva inredningsuppdraget. Vilken business handlar det om, vad du vill ha hjälp med samt vilka förutsättningarna och önskemål finns? När beställningen är slutförd kommer en av våra inredare ta kontakt för att påbörja uppdraget. Detta sker normalt inom 1-3 arbetsdagar.",
  "start_text_alt": " Du kommer nu gå igenom några korta steg där du berättar vad du vill ha hjälp med samt vilka förutsättningarna och önskemål som finns för inredningsuppdraget. När beställningen är slutförd kommer en av våra inredare ta kontakt med dig för att påbörja uppdraget. Detta sker inom 1-4 arbetsdagar. Tack för förtroendet!'",
  "start_button": "Då sätter vi igång",
  "basket_regular_text": "Efter slutfört köp får du en orderbekräftelse och inom 1-4 arbetsdagar kontaktar en av våra inredare dig för att sätta igång med ditt inredningsprojekt.",
  "basket_concept_text": "Efter slutfört köp kontaktar någon av våra inredare dig för att påbörja uppdraget.",
  "basket_concept_text_title": "Detta ingår i tjänsten KOMPLETT:",
  "basket_concept_text_title_biz": "Detta ingår i tjänsten BUSINESS:",
  "basket_concept_text_li_1": "Professionell inredningshjälp.",
  "basket_concept_text_li_2": "Dialog med inredaren",
  "basket_concept_text_li_3": "Moodboard för uppdraget",
  "basket_concept_text_li_4": "Planskiss över rummet",
  "basket_concept_text_li_5": "Visualisering i 2D/3D",
  "basket_concept_text_li_6": "Digital produktlista",
  "basket_concept_text_li_7": "Kontinuerlig avstämning",
  "basket_concept_text_li_8": "En ev. omarbetning ingår",
  "basket_concept_li_end_text": "Läs om vår Nöjd Kund-garanti",
  "basket_guide_text_1": "Efter slutfört köp kontaktar vår inredare dig för att påbörja uppdraget.",
  "basket_guide_text_2": "Detta ingår i tjänsten TIPS & RÅD: ",
  "basket_guide_text_li_1": "Professionell inredningshjälp",
  "basket_guide_text_li_2": "30 min samtal med inredaren",
  "basket_guide_text_li_3": "Digital produktlista",
  "basket_guide_text_3": "Läs om vår Nöjd Kund-garanti",
  "basket_guide_text_4": "här",
  "basket_get_basket_title_1": "Rådgivning",
  "basket_get_basket_title_2": "2D Plan",
  "basket_get_basket_title_3": "Visualisering i 3D",
  "basket_get_basket_text_1": "Varukorg",
  "basket_get_basket_text_2": "Lägg till ytterligare rum",
  "basket_get_basket_text_3": "Lägg till en presentkod",
  "basket_get_basket_text_4": "presentkod tillagd!",
  "basket_get_basket_dscode": "Presentkod",
  "basket_get_basket_text_5": "Lägg till",
  "basket_get_basket_button": "Bakåt",
  "business_order_get_title_text_1": "Beskrivning av inredningsuppdraget",
  "business_order_get_title_text_2": "Hur stort är utrymmet som ska inredas?",
  "business_order_get_title_text_3": "Hur ser budgeten ut?",
  "business_order_business_order_message_info_1": "Berätta kortfattat vilken typ av hjälp som efterfrågas. Vad är det för business det gäller? Vad finns det för behov och önskemål och hur ser förutsättningarna ut?",
  "business_order_business_order_message_info_2": "Självklart går det bra att återanvända precis hur mycket som helst av de möbler och den inredning som redan finns. Vi ger tips på hur du kan förnya och återanvända existerande möbler och inredning samt förslag på nya inköp. Det finns inget som helst köptvång av nya produkter och inredningstjänsten har alltid ett fast pris.",
  "business_space_count_info_text_title": "Få ett designförslag på hur du kan färgsätta, möblera och inreda din lokal. Vi hjälper dig skapa en känsla som gör att kunder och medarbetare längtar tillbaka.",
  "business_space_count_info_text_1": "Dialog med inredaren",
  "business_space_count_info_text_2": "Moodboard för uppdraget",
  "business_space_count_info_text_3": "Anpassning till din business",
  "business_space_count_info_text_4": "Planskiss med möblering",
  "business_space_count_info_text_5": "Visualisering i 2D/3D",
  "business_space_count_info_text_6": "Digital produktlista",
  "business_space_count_info_text_7": "En ev. omarbetning ingår",
  "business_space_count_bsp_text_1": "Lägg till det antal rum eller space (del av rum) som du vill ha hjälp att inreda och möblera. Ett rum/space kan vara upp till 40 kvm. Är rummet eller delen av rummet större än det så lägg till ytterligare ett rum/space. Är du osäker på hur du ska göra, kontakta oss på",
  "business_space_count_bsp_text_2": "så hjälper vi dig.",
  "business_space_count_bsp_text_3": "Antal rum/space",
  "choose_furnishings_text_1": "Ge din inredare en bra start på uppdraget genom att berätta vilken stil som tilltalar dig. Du kan välja en eller flera bilder.",
  "choose_furnishings_text_2": "Du kan även länka till en av anslagstavla på Pinterest",
  "choose_furnishings_text_3": "Pinterest, lägg t.ex. in en länk till en anslagstavla",
  "choose_image_step_text_1": "Skandinavisk",
  "choose_image_step_text_2": "Modern",
  "choose_image_step_text_3": "Klassisk",
  "choose_room_text_1": "Antal",
  "choose_room_text_2": "Lägg till",
  "choose_room_text_3_1": "Lägg till det rum eller space (del av ett rum) som du vill ha hjälp att inreda och möblera. Ett rum/space kan vara upp till 30 kvm. Är rummet eller delen av rummet större än det så lägg till ytterligare ett rum/space. Är du osäker på hur du ska göra, kontakta oss på",
  "choose_room_text_3_2": "så hjälper vi dig.",
  "choose_room_text_4": "Arbetsplats",
  "choose_room_text_5": "Uteplats",
  "choose_room_text_6": "Hall",
  "choose_room_text_7": "Kök",
  "choose_room_text_8": "Matplats",
  "choose_room_text_9": "Sovrum",
  "choose_room_text_10": "Vardagsrum",
  "choose_room_text_11": "Annat",
  "contact_text_1": "Fyll i din information så kontaktar vi dig för att diskutera vilka inredning som kan passa för just ditt hem. Tillsammans går du och vår designer igenom hur din befintliga inredning kan anpassas till den nya.",
  "contact_text_2": "Förnamn och efternamn",
  "contact_text_3": "Telefonnummer",
  "contact_text_4": "E-post",
  "contact_text_5": "Kommentar (valfritt)",
  "contact_text_6": "Skriv gärna in ditt användarnamn på Instagram och Pinterest så att vi kan lära känna dig bättre.",
  "contact_text_7": "Instagram (valfritt)",
  "contact_text_8": "Pinterest (valfritt)",
  "file_step_text_1": "Kunde inte ladda upp filen",
  "file_step_text_2": "Prova igen lite senare",
  "file_step_text_3": "Ta bort",
  "file_step_text_4": "Filma gärna långsamt och gå runt i rummet. Se till att takhöjden syns! En lagom längd på filmen är 15-20 sekunder. Berätta gärna ifilmen Vad du tänker kring rummet ",
  "file_step_text_5": "Lägg gärna till fler bilder för att visa mer detaljer.",
  "file_step_text_6": "Skaffat ny bostad? Prospekt, planritning samt bilder från visning går också bra att ladda upp.",
  "file_step_text_7": "Lägg till film ",
  "file_step_text_8": "eller bild",
  "file_step_text_9": "Är du säker på att du inte vill skicka in någon bild?",
  "file_step_text_10": "Nästa",
  "file_step_text_11": "Ja",
  "info_step_text_1": "Vad är det du saknar?",
  "info_step_text_2": "Dekoration",
  "info_step_text_3": "Möbler",
  "info_step_text_4": "Blandat",
  "info_step_text_5": "Öppen för förslag",
  "info_step_text_6": "Vem ska använda rummet?",
  "info_step_text_7": "Beskriv om rummet är tänkt att användas av specefika personer (ex. ett barn, vuxen) eller om det är ",
  "info_step_text_8": "ett allmänt rum",
  "info_step_text_9": "Beskrivning",
  "info_step_text_10": "Vilka färger gillar du?",
  "info_step_text_11": "Lista gärna några färger som du gillar så att vår inredare kan gör ett bättre val",
  "option_text_1": "Vill du beställa flera rum med olika tjänster så lägger du en beställning med x antal rum per typ av tjänst. Alla våra tjänster har ett fast pris och utförs helt online.",
  "option_text_2": "Få muntliga tips och råd hur du kan inreda ditt rum samt en moodboard som hjälper dig skapa den stil och känsla du önskar.",
  "option_text_3": "Samtal med inredaren (ca 30 min)",
  "option_text_4": "Moodboard för rummet",
  "option_text_5": "Få ett tydligt förslag på hur du kan möblera ditt befintliga eller nya boende baserat på dina behov och önskemål.",
  "option_text_6": "Planskiss över rummet",
  "option_text_7": "Digital produktlista",
  "option_text_8": "Visualisering i 3D",
  "option_text_9": "Få ett fullständigt förslag på hur du kan möblera och inreda för att skapa den känsla och de funktioner du önskar i ditt hem.",
  "option_text_10": "Planskiss för möblering",
  "order_text_1": "Vilken typ av hjälp vill du ha?",
  "order_text_2": "Vilket space vill du ha hjälp med?",
  "order_text_3": "Vilken stil gillar du?",
  "order_text_4": "Budget för inköp av nya produkter?",
  "order_text_5": "Meddelande till inredaren",
  "budget_text_1": "Vilken budget har du för nya inköp? Självklart kan du återanvända precis hur mycket du vill av det du redan har hemma. Det finns inget som helst köptvång av nya produkter och själva inredningstjänsten har alltid ett fast pris.",
  "message_text_1": "Berätta kortfattat vad det är du vill ha hjälp med. Vad har du för behov och önskemål och hur ser förutsättningarna ut? När du fått kontakt med din inredare kan du skicka bilder och film på det space du vill ha hjälp med.",
  "save_order_1": "Spara dina val?",
  "save_order_2": "Skickat!",
  "save_order_3": "Ange din mail så får du en länk så att du komma tillbaka till dina val",
  "save_order_4": "Spara",
  "add_budget_order_text": "Kunde inte lägga till budget. Prova igen lite senare",
  "add_comment_order_text": "Kunde inte lägga till kommentar. Prova igen lite senare",
  "add_discount_message_text_info": "Kunde inte hitta rabattkod, prova med en annan",
  "add_discount_message_text_err": "Kunde inte lägga till rabattkoden. Prova igen lite senare",
  "add_files_text": "Kunde inte lägga till filerna. Prova igen lite senare",
  "add_option_text": "Kunde inte skapa order. Prova igen lite senare",
  "add_pinterest_text": "Kunde inte lägga till pinterest. Prova igen lite senare",
  "add_room_text": "Kunde inte lägga till/ta bort rum till ordern. Prova igen lite senare",
  "add_whatappeal_text": "Kunde inte lägga Vilken stil passar dig bäst. Prova igen lite senare",
  "add_whatstyle_text": "Kunde inte lägga Vilken stil passar dig bäst. Prova igen lite senare",
  "create_checkout_text": "Kunde inte skapa betalningen. Prova igen lite senare",
  "remove_file_text": "Kunde inte ta bort filen. Prova igen lite senare",
  "send_save_text": "Kunde inte skapa länken. Prova igen lite senare",
  "start_order_text": "Kunde inte skapa ordern. Prova igen lite senare",
  "update_checkout_text": "Kunde inte uppdatera. Prova igen lite senare",
  "add_comment_text_1": "Meddelande till inredaren",
  "add_pinterest_text_1": "Pinterest sparad!",
  "confirmation_text_1": "Tack för att du valt Hemset!",
  "confirmation_text_2": "Vi ser fram emot att hjälpa dig få ett trivsamt hem!",
  "confirmation_text_3": "En inredare kommer kontakta dig inom 1-4 dagar.",
  "currency": "kr",
  "language": "se",
  "guidancePrice": "895",
  "twoDPlanPrice": "1695",
  "conceptPrice": "2995",
  "businessPrice": "4995",
  "budget_min": 5000,
  "budget_max": 100000,
  "budget_step": 500,
  "budget_initial_price": 10000
}